import React from 'react';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

if (['production'].includes(import.meta.env.MODE)) {
  Sentry.init({
    dsn:
      'https://8b1e80df77654b9f8020db2e5504581c@o4504532881113088.ingest.sentry.io/4504532885110784',
    integrations: [new BrowserTracing()],
    environment: import.meta.env.MODE,
    tracesSampleRate: 0.1,
  });
}

console.log(import.meta.env.MODE);

const root = createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Suspense>
      <App />
    </Suspense>
  </BrowserRouter>,
);
