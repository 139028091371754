import {
  ClubControllerApi,
  ApiClient,
  MenuControllerApi,
  MenuSchedulingControllerApi,
  TableZoneControllerApi,
  BannerControllerApi,
  TableControllerApi,
  UserControllerApi,
  CountryControllerApi,
  BrandControllerApi,
} from '@client';
import superagent from 'superagent';
import { Buffer } from 'buffer';
import axios from 'axios';

export function logout() {
  localStorage.removeItem('auth@token');
  localStorage.removeItem('isAdmin');
  localStorage.clear();
}

export function getAuthToken() {
  return localStorage.getItem('auth@token');
}

export function isLoggedIn() {
  return getAuthToken() != null;
}

// TODO: find a better way
export function isAdmin() {
  return localStorage.getItem('isAdmin') === 'true';
}
export function setIsAdmin(isAdmin) {
  return localStorage.setItem('isAdmin', isAdmin);
}

export async function setAuthToken(authToken) {
  localStorage.setItem('auth@token', authToken);
}

// TODO: if unauthorized redirect to login
axios.defaults.baseURL = import.meta.env.VITE_API_URL;
axios.interceptors.request.use(config => {
  const token = getAuthToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const apiClient = new ApiClient(import.meta.env.VITE_API_URL);
apiClient.applyAuthToRequest = request => {
  const token = getAuthToken();
  request.set({ Authorization: 'Bearer ' + token });
};
export const clubApi = new ClubControllerApi(apiClient);
export const menuApi = new MenuControllerApi(apiClient);
export const menuSchedulingApi = new MenuSchedulingControllerApi(apiClient);
export const tableZoneApi = new TableZoneControllerApi(apiClient);
export const bannerApi = new BannerControllerApi(apiClient);
export const tableApi = new TableControllerApi(apiClient);
export const userApi = new UserControllerApi(apiClient);
export const countryApi = new CountryControllerApi(apiClient);
export const brandsApi = new BrandControllerApi(apiClient);

export function uploadToS3(s3Url, payload, contentType) {
  const base64Data = new Buffer.from(payload.replace(/^data:image\/\w+;base64,/, ''), 'base64');
  return superagent
    .put(s3Url)
    .send(base64Data)
    .set('Content-Type', contentType);
}

// TODO fix this
export function loginApi(username, password, totp) {
  return superagent.post(import.meta.env.VITE_API_URL + '/login').send({
    username,
    password,
    totp,
  });
}

// TODO: fix this
export async function getMenuPdf(clubId, menuId) {
  const token = getAuthToken();
  return superagent
    .get(import.meta.env.VITE_API_URL + `/clubs/${clubId}/menu/${menuId}/pdf`)
    .responseType('blob')
    .set({ Authorization: 'Bearer ' + token });
}
