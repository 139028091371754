import React from 'react';
import { CircularProgress, Stack } from '@mui/material';

export const Spinner = () => {
  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          px: 2,
          py: 12,
          minHeight: '100vh',
          width: 1,
          mx: 'auto',
          flexShrink: 0,
        }}
      >
        <CircularProgress color="inherit" />
      </Stack>
    </>
  );
};
