import React from 'react';
import ErrorBoundary from 'components/error-boundary';
import * as Sentry from '@sentry/react';
import ThemeProvider from 'theme';
import Router from 'routes';

import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { messages as englishMessages } from '@locales/en/messages';
import { messages as italianMessages } from '@locales/it/messages';
import { languages } from '@services/languages';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { SnackbarProvider, useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { logout } from '@services/api';
import { registerSW } from 'virtual:pwa-register';

i18n.load({
  en: englishMessages,
  it: italianMessages,
});
i18n.activate(languages[0]);

export default function App() {
  const SnackbarDismissButton = ({ id }) => {
    const { closeSnackbar } = useSnackbar();
    return (
      <CloseIcon
        onClick={() => {
          closeSnackbar(id);
        }}
      />
    );
  };

  const MainContent = () => {
    const { enqueueSnackbar } = useSnackbar();

    const updateSW = registerSW({
      onNeedRefresh() {
        enqueueSnackbar(i18n._('Update found, click here to install it'), {
          variant: 'info',
          onClick: updateSW,
          persist: true,
          action: () => {},
        });
      },
    });

    const clientSharedOptions = {
      retry: (failureCount, error) => {
        return failureCount <= 3 && '4' !== error?.status?.toString().charAt(0);
      },
      onError: error => {
        if (error.status === 401) {
          logout();
          enqueueSnackbar(i18n._('Your session has expired, please log in again'), {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar(i18n._('An error occurred'));
          Sentry.captureException(error);
        }
      },
    };
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: { ...clientSharedOptions, refetchOnWindowFocus: false },
        mutations: clientSharedOptions,
      },
    });

    return (
      <QueryClientProvider client={queryClient}>
        <I18nProvider i18n={i18n}>
          <ThemeProvider>
            <Router />
          </ThemeProvider>
        </I18nProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    );
  };

  return (
    <SnackbarProvider
      maxSnack={3}
      action={key => <SnackbarDismissButton id={key} />}
      variant="error"
      preventDuplicate
    >
      <ErrorBoundary>
        <MainContent />
      </ErrorBoundary>
    </SnackbarProvider>
  );
}
