import React from 'react';
import { lazy } from 'react';

// ----------------------------------------------------------------------

const Page401 = lazy(() => import('pages/401'));
const Page404 = lazy(() => import('pages/404'));
const Page500 = lazy(() => import('pages/500'));
const PageAppUpdate = lazy(() => import('pages/app-update'));

// ----------------------------------------------------------------------

export const errorRoutes = [
  { path: '401', element: <Page401 /> },
  { path: '404', element: <Page404 /> },
  { path: 'error', element: <Page500 /> },
  { path: 'update', element: <PageAppUpdate /> },
];
