import React from 'react';
import { isLoggedIn } from '@services/api';
import { Navigate } from 'react-router-dom';
import { paths } from './paths';

export default function AuthenticatedRoute({ children }) {
  if (!isLoggedIn()) {
    return <Navigate to={paths.login} replace />;
  }
  return children;
}
