import React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';
import { customShadows } from './custom-shadows';
import { createPresets } from './presets';
import { componentsOverrides } from './overrides';

// ----------------------------------------------------------------------

export default function ThemeProvider({ children }) {
  const themeMode = 'light';
  const themeDirection = 'ltr';
  const themeColorPresets = 'preset03';

  const presets = createPresets(themeColorPresets);

  const themeConfig = {
    palette: {
      ...palette(themeMode),
      ...presets.palette,
    },
    customShadows: {
      ...customShadows(themeMode),
      ...presets.customShadows,
    },
    direction: themeDirection,
    shadows: shadows(themeMode),
    shape: { borderRadius: 8 },
    typography,
  };

  const theme = createTheme(themeConfig);

  theme.components = componentsOverrides(theme);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
