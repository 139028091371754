import React from 'react';
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { paths } from 'routes/paths';

// ----------------------------------------------------------------------

// Qr Code Menu
const QrCodeHomepage = lazy(() => import('@pages/qr-code-menu/qr-code-homepage'));
const QrCodeMenuPage = lazy(() => import('@pages/qr-code-menu/qr-code-menu-page'));

// ----------------------------------------------------------------------

export const qrCodeRoutes = [
  {
    element: <Outlet />,
    children: [
      { path: paths.qrcodeMenu.root, element: <QrCodeHomepage /> },
      { path: paths.qrcodeMenu.menu, element: <QrCodeMenuPage /> },
    ],
  },
];
