export const languages = ['it', 'en'];

export const languageMetadata = {
  it: {
    name: 'Italiano',
    flag: 'emojione-v1:flag-for-italy',
  },
  en: {
    name: 'English',
    flag: 'emojione-v1:flag-for-united-kingdom',
  },
};
