import React from 'react';
import PropTypes from 'prop-types';

import Header from '../common/header-simple';

// ----------------------------------------------------------------------

export default function SimpleLayout({ children }) {
  return <>{children}</>;
}

SimpleLayout.propTypes = {
  children: PropTypes.node,
};
