import React from 'react';
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import AuthBackgroundLayout from 'layouts/auth/background';

import { paths } from 'routes/paths';

// ----------------------------------------------------------------------

const LoginPage = lazy(() => import('@pages/auth/login-page'));
const SignupPage = lazy(() => import('@pages/auth/signup-page'));
const PasswordResetPage = lazy(() => import('@pages/auth/password-reset-page'));
const PasswordRecoveryPage = lazy(() => import('@pages/auth/password-recovery-page'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    element: (
      <AuthBackgroundLayout>
        <Outlet />
      </AuthBackgroundLayout>
    ),
    children: [
      { path: paths.login, element: <LoginPage /> },
      { path: paths.signup, element: <SignupPage /> },
      { path: paths.passwordReset, element: <PasswordResetPage /> },
      { path: paths.passwordRecovery, element: <PasswordRecoveryPage /> },
    ],
  },
];
